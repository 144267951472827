'use strict';

/**
 * Trigger a Looks We Love modal on page load
 * based off the Product Id of the Product Set in the url,
 * which is set via a "quickLookId" url parameter, ie. /women/features/looks-we-love.html?quickLookId=blue-look
 * @param {string} quickLookId  - Quick view parameter
 */
function triggerLooksWeLoveModal(quickLookId) {
    $(document).ready(function () {
        $('.js-quickview').each(function () {
            let $link = $(this);
            if ($link.attr('href').indexOf('pid=' + quickLookId) >= 0) {
                $link.click();
                return false; // break out of .each() loop
            }
            return true; // continue to next item in .each() loop
        });
    });
}

/**
 * Checks if "quickLookId" parameter is found in the url
 *  @returns {boolean} True if "quickLookId" parameter is found in the url, else false
 */
function hasQuickLookIdParam() {
    const url = window.location.href;
    if (url.indexOf('quickLookId=') > -1) {
        let urlObject = new URL(url);
        let quickLookId = urlObject.searchParams.get('quickLookId');
        triggerLooksWeLoveModal(quickLookId);
    }
    return false;
}

module.exports = {
    looksWeLove: function () {
        hasQuickLookIdParam();
    }
};
